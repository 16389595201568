import React from "react"
import * as S from "./styled"

const TermsCondition = () => {
  return (
    <S.Container>
      <iframe src="https://app.termly.io/document/terms-of-use-for-saas/37875459-86b0-474a-8371-fd0c9ea07edb" title="TermsCondition"></iframe>
    </S.Container>
  );
}

export default TermsCondition
