import React from 'react'

import Layout from "../components/Layout"
import Menu from "../components/Menu"
import SEO from "../components/seo"
import TermsCondition from "../components/TermsCondition"

import { ThemeProvider } from 'styled-components'
import General from '../styles/theme'

const Terms = () => (
  <ThemeProvider theme={General}>
    <Layout>
    <SEO title="Privacy Policy" />
    <Menu />
    <div>
      <TermsCondition/>
    </div>
    </Layout>
  </ThemeProvider>
)

export default Terms